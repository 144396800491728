// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/lib/utils.ts"
);
import.meta.hot.lastModified = "1715866721442.323";
}
// REMIX HMR END

import { type ClassValue, clsx } from "clsx"
import FormData from "form-data";
import Mailgun from "mailgun.js";
import { useEffect, useState } from "react";
import { twMerge } from "tailwind-merge"
import { ZodError, ZodSchema, boolean, z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function formatNumber(num: number): string {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
export function formatString(str: string): string {
  return str.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function useDebounce<T = any>(value: T, delay: number): [T, boolean] {
  console.log(value, 'value');

  const [debouncedValue, setDebouncedValue] = useState(value);
  let [isDebouncing, setDebouncing] = useState(false);
  useEffect(
    () => {

      setDebouncing(true);
      const handler = setTimeout(() => {
        setDebouncing(false);

        setDebouncedValue(value);
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay]
  );
  return [debouncedValue, isDebouncing];
}

export function formDataToText(formData: any) {
  let text = '';

  for (let [key, value] of formData.entries()) {
    text += `${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}\n`;
  }

  return text;
}

export async function validationAction({
  formData,
  schema,
}: {
  formData: FormData,
  schema: ZodSchema,
}) {
  //@ts-ignore
  const data = Object.fromEntries(Array.from(formData));

  try {
    const result = schema.parse(data);

    return { data: result, errors: null };
  } catch (e) {
    console.error(e);
    const errors = e as ZodError;
    if(!errors.issues) {
      errors.issues = data;
    }

    return {
      data: data,
      errors: errors.issues.reduce((acc, issue) => {
        const key = issue.path[0];

        acc[key] = issue.message;

        return acc;
      }, {} as { [key: string]: string }),
    };
  }
}


export function validationSchema(value: string) {
  switch (value) {
    case "loans":
      return z.object({
        loan: z.string().min(1, { message: 'Please enter a valid loan amount' }),
        // machines: z.string().optional(),
        // inventory: z.string().optional(),
        // realestate: z.string().optional(),
        // other: z.string().optional(),
        // others: z.string().optional(),
        // invoicesBox: z.string().optional(),
        // invoices: z.string().optional(),
        // enventoryBox: z.string().optional(),
        // enventory: z.string().optional(),
        // exmachinesBox: z.string().optional(),
        // exmachines: z.string().optional(),
        // realstatesBox: z.string().optional(),
        // realstates: z.string().optional(),
        // garantyBox: z.string().optional(),
        // garanty: z.string().optional(),
        // personBox: z.string().optional(),
        // person: z.string().optional(),
        revenue: z.string().min(1, { message: 'Please enter a valid revenue amount' }),
        egencapital: z.string().min(1, { message: 'Please enter a valid egenkapital amount' }),
        lastResults: z.string().min(1, { message: 'Please enter a valid lastResults amount' }),
      });
    case "overspending":
      return z.object({
        loan: z.string().min(1, { message: 'Please enter a valid loan amount' }),

      });
    case "placement":
      return z.object({

      });
    default:
      throw new Error(`Invalid value: ${value}`);
  }
}


